import React from "react";
import Layout from "components/Layout";
import { graphql } from "gatsby";
import EventItem from "../components/EventItem";

const Component = props => {
  const {
    data: {
      allContentfulEvent: { edges: events }
    }
  } = props;
  return (
    <Layout>
      <h1>Konzerte</h1>
      {events.map(({ node: event }) => (
        <EventItem event={event} key={event.id} />
      ))}
    </Layout>
  );
};

export default Component;

export const Events = graphql`
  query Events {
    allContentfulEvent(sort: { fields: [startTime], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          startTime
          location
          description {
            id
            description
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
    }
  }
`;
