import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import moment from "moment";

const EventItem = props => {
  const { event } = props;
  return (
    <Item>
      <Content>
        <Title>{event.title}</Title>
        <SubTitle>
          {event.location}
          <br /> {moment(event.startTime).format("LLL")}
        </SubTitle>
        {event.description ? (
          <Description
            dangerouslySetInnerHTML={{
              __html: event.description.childMarkdownRemark.html
            }}
          />
        ) : null}
      </Content>
    </Item>
  );
};

EventItem.propTypes = {
  event: PropTypes.object.isRequired
};

export default EventItem;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
`;
const Content = styled.div`
  margin: 0 0 0 1rem;
  display: flex;
  flex-flow: nowrap column;
  width: 75%;
`;
const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;
const SubTitle = styled.div`
  margin-bottom: 1rem;
`;
const Description = styled.div``;
